<template>
    <b-modal
        size="lg"
        hide-footer
        v-model="isOpen"
        title="Edit Orders"
        id="editOrderModal"
        ref="editOrderModal"
        modal-class="edit-order-modal"
    >
        <template v-slot:modal-header="{ close }">
            <span class="fs18 lh28 fw500">
                <span>Editing for {{editOrderID .length != 0 ? editOrderID.length : selectedImportedID.length == 0 ? [skuOrderID.length] : selectedImportedID.length}} selected orders
                </span>
            </span>

            <b-button variant="rounded" @click="close()">
                <i class="icon-close-variant"></i>
            </b-button>
        </template>        

        <b-form @submit.prevent="formSubmit">
            <div class="mw596 mx-auto pt-46">
                <div class="row" v-if="selectedEditOrder.length != 0 && !haseditOrderID">
                    <div class="col">
                        <b-form-group
                          id="warehouse-field" label="Warehouse" label-for="warehouse" :description="descriptionAddress"
                        >
                            <multiselect
                                id="warehouse"
                                v-model="form.warehouse"
                                :options="warehouses"
                                label="name"
                                @input="editWarehouseList(form.warehouse.id)"
                                :searchable="true"
                                :show-labels="false"
                            ></multiselect>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-3 col-6">
                        <b-form-group
                          id="length-field"
                          label="Length (cm)"
                          label-for="length"
                        >
                            <b-input 
                                id="length" 
                                v-model="form.itemLength"
                                @change="dimInput" 
                                type="number" 
                                placeholder="0"
                                min="1"
                                max="999"
                                :required="enableProp"
                            ></b-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-3 col-6">
                        <b-form-group id="width-field" label="Width (cm)" label-for="width">
                            <b-input 
                                id="width" 
                                v-model="form.itemWidth"
                                @change="dimInput" 
                                type="number" 
                                placeholder="0"
                                min="1"
                                max="999"
                                :required="enableProp"
                            ></b-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-3 col-6">
                        <b-form-group
                            id="height-field"
                            label="Height (cm)"
                            label-for="height"
                        >
                            <b-input 
                                id="height" 
                                v-model="form.itemHeight"
                                @change="dimInput" 
                                type="number" 
                                min="1"
                                max="999"
                                placeholder="0"
                                :required="enableProp"
                            ></b-input>
                        </b-form-group>
                    </div>

                    <div class="col-md-3 col-6">
                        <div
                            class="form-group"
                            id="weight-field"
                            label="Weight (kg)"
                            label-for="weight"
                        >
                            <label for="weight">
                                Weight (kg)
                            </label>
                            <b-input 
                                id="weight" 
                                @change="dimInput" 
                                v-model="form.itemWeight"
                                type="number" 
                                placeholder="0.00"
                                step="0.01"
                                :required="enableProp"
                                :min="$store.state.minW"
                                :max="$store.state.maxW"
                            ></b-input>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-6">
                        <b-form-group id="mode-type-field" label="Mode" label-for="mode-type">
                            <multiselect
                                id="mode-type"
                                v-model="form.mode"
                                :options="$store.getters.mode.domestic"
                                label="name"
                                :searchable="false"
                                :show-labels="false"
                                placeholder="Select mode type"
                            ></multiselect>
                         </b-form-group>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-md-6 d-flex align-items-center mt-10 mb-10" v-if="saveSKUInTable == true">
                        <b-form-group class="d-inline mb-0">
                            <b-form-checkbox
                                id="agreement-check"
                                v-model="form.saveasSKU"
                                name="agreement-check"
                            >
                                Save data in SKU
                            </b-form-checkbox>
                        </b-form-group>
                    </div>
                </div>

                <div class="row pt-24">
                    <div class="col-md-auto col-6 pr-sm-4 pr-xs-4">
                        <b-button
                            variant="primary btn-lg w-sm-100"
                            class="m-0 mr-8"
                            type="submit"
                            :disabled="!(form.itemHeight || form.warehouse || form.mode) || isBusy"
                        >
                            Update <b-spinner v-if="isBusy" class="sm"></b-spinner>
                        </b-button>
                    </div>

                    <div v-if="!isBusy" class="col-md-auto col-6 pl-sm-4 pl-xs-4">
                        <b-button
                          id="btnHide"
                          variant="secondary-modal btn-lg w-sm-100"
                          @click="hideModal"
                          class="m-0"
                        >
                          Cancel
                        </b-button>
                    </div>
                </div>

            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { VMoney } from "v-money";
import Multiselect from "vue-multiselect";

export default {
    name: "EditOrderModal",
    props: ['modalOpen', 'orderIDs', 'selectedEditOrder', 'skuOrderID', 'editOrderID', 'selectedImportedID', 'saveSKUInTable'],

    data() {
        return {
            value: null,
            isBusy: false,
            typeValue: null,
            isOpen: this.modalOpen,
            modeTypes: this.$store.getters.modes,
            money: {
                decimal: ",",
                thousands: ".",
                precision: 2,
                masked: false,
            },
            descriptionAddress: "",
            form: {},
            enableProp: false,
        };
    },

    components: {
        Multiselect,
    },

    directives: { money: VMoney },

    methods: {

        dimInput() {
            this.enableProp = true;
        },
        hideModal() {
            this.$bvModal.hide("editOrderModal");
        },
        async editWarehouseList(warehouseID) {

            const res = await this.axios.get(`/settings/warehouses/${warehouseID}`)
            const warehouse = res.data[1].response.warehouse
            const address = JSON.parse(warehouse.address)

            this.descriptionAddress = address.address1 + address.address2 + address.address3 + ' ' + address.city

        },
        async formSubmit() {
            this.isBusy = true
            const newData = this.form;

            if(!(newData.itemHeight || newData.mode || newData.warehouse)) {
                this.popToast("failed", "Empty!", 'You have not updated any field.');
                return false;
            }

            if(!(this.selectedEditOrder && this.selectedEditOrder.length) && newData.warehouse) { 
                this.popToast("failed", "Empty!", 'You have not updated any field.');
                return false;
            }

            // console.log(this.editOrderID)
            // console.log(this.selectedEditOrder)
            // console.log(this.skuOrderID)


            this.form.modeType = newData.mode ? newData.mode.value : '';
            this.form.warehouseID = newData.warehouse ? newData.warehouse.id : '';

            let editOrderID = Array.isArray(this.editOrderID) ? this.editOrderID : this.editOrderID ? [this.editOrderID] : [];
            let selectedEditOrder = Array.isArray(this.selectedEditOrder) ? this.selectedEditOrder : this.selectedEditOrder ? [this.selectedEditOrder] : [];
            let skuOrderID = Array.isArray(this.skuOrderID) ? this.skuOrderID : this.skuOrderID ? [this.skuOrderID] : [];

            this.form.orderID = editOrderID.length != 0 ? editOrderID : selectedEditOrder.length == 0 ? skuOrderID : selectedEditOrder;

            let updatedData = Object.assign({}, newData);

            delete updatedData.warehouse
            delete updatedData.mode

            if(this.saveSKUInTable == true) {

                console.log(updatedData)

                let response = await this.axios.post('/editdim', updatedData);

                if (response.data.success == true) {
                    this.$emit('updatePackages', this.form.orderID)
                    if (this.$route.path.includes('/orders')) {
                        setTimeout(() => {
                            this.hideModal();
                            this.$parent.orderPageData();
                            this.$parent.allSelected = false
                        }, 2000)  
                    }
                }
                else {
                    this.hideModal();
                }
                this.isBusy = false
            }
            else {

                let response = await this.axios.post('/order/quickedit', updatedData);

                if (response.data.success) {
                    this.popToast("booked", "Success", response.data.message);

                    this.form = {}

                    if (this.$route.path.includes('/orders')) {
                        setTimeout(() => {
                            this.hideModal();
                            this.$parent.orderPageData();
                            this.$parent.allSelected = false
                        }, 2000)  
                    }
                    else {
                        this.hideModal();
                        this.$emit('bookFromTable', this.form.orderID)
                    }

                }
                else {
                    this.popToast("failed", "failed", response.data.error);
                }
                this.isBusy = false
            }

        },
    },

    computed: {
        ordersSelected() {
            return this.selectedEditOrder.length
        },
        haseditOrderID() {
            return this.editOrderID.length >= 1;
        },
        warehouses() {
            return this.$store.getters.warehouses.map(i => {
                return {
                        name: i.name, 
                        id: i.id,
                        $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                        address: i.address,
                    };
            })
        }
    },
    watch: {
        isOpen(newValue) {
            if(!newValue) {
                this.$emit('update:editOrderID', [])
                this.$emit('update:selectedEditOrder', [])
                this.$emit('update:selectedImportedID', [])
                this.$emit('update:saveSKUInTable', false)
                this.form = {};
                this.$parent.orderFromRowBook = false
            }
        }
    }
};
</script>
